import React, { createContext, useContext, useEffect, useState } from "react";
const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const initialAuthState = {
    token: "",
  };
  const [authState, setAuthState] = useState(initialAuthState);

  const signIn = (token) => {
    try {
      if (token) {
        localStorage.setItem("@yrcToken", token);
        setAuthState({
          ...authState,
          token,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signOut = () => {
    try {
      localStorage.removeItem("@yrcToken");
      setAuthState(initialAuthState);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("@yrcToken");
        signIn(token);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ authState, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider as default, useAuth };

import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

const Home = lazy(() => import("../views/Home"));
const Gallery = lazy(() => import("../views/gallery/Gallery"));
const Images = lazy(() => import("../views/gallery/Images"));
const News = lazy(() => import("../views/news/News"));
const Events = lazy(() => import("../views/events/Events"));
const Event = lazy(() => import("../views/events/Event"));
const NewsSingle = lazy(() => import("../views/news/NewsSingle"));
const About = lazy(() => import("../views/about/About"));
const Faq = lazy(() => import("../views/faq/Faq"));
const Contact = lazy(() => import("../views/contact/Contact"));
const Rules = lazy(() => import("../views/rules/Rules"));
// const SignIn = lazy(() => import("../views/auth/SignIn"));
const SignUp = lazy(() => import("../views/auth/SignUp"));
const Reset = lazy(() => import("../views/auth/Reset"));
const PasswordReset = lazy(() => import("../views/auth/PasswordReset"));
const Profile = lazy(() => import("../views/profile/Profile"));
const Search = lazy(() => import("../views/profile/Search"));
const Application = lazy(() => import("../views/application/Application"));
// const Cv = lazy(() => import("../views/cv"));
const EditApplication = lazy(() =>
  import("../views/application/EditApplication")
);
// const Messenger = lazy(() => import("./views/chat/Messenger"));
const Blogs = lazy(() => import("../views/blog/Blogs"));
const Blog = lazy(() => import("../views/blog/Blog"));
const Public = lazy(() => import("../views/public/Public"));
const AllEvents = lazy(() => import("../views/events/AllEvents"));

const Test = lazy(() => import("../views/Test"));

const Routes = () => {
  const { authState } = useAuth();
  return (
    <Router>
      <Switch>
        {authState?.token && <Redirect from="/signin" to="/" exact />}
        {authState?.token && <Redirect from="/signup" to="/" exact />}
        {authState?.token && <Redirect from="/reset" to="/" exact />}
        {!authState?.token && (
          <Redirect from="/m/:username" to="/signin" exact />
        )}
        {/* {!user && <Redirect from="/t" to="/signin" exact />} */}

        <Route exact path="/" component={Home} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/images/:galleryId" component={Images} />
        <Route exact path="/newsFeed" component={News} />
        <Route exact path="/news/:newsId" component={NewsSingle} />
        <Route exact path="/events/:categoryId" component={Events} />
        <Route exact path="/allEvents" component={AllEvents} />
        <Route exact path="/event/:eventId" component={Event} />
        <Route exact path="/about" component={About} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/rules" component={Rules} />

        <Route exact path="/application/:eventId" component={Application} />
        <Route
          exact
          path="/editApplication/:eventId"
          component={EditApplication}
        />

        {/* <Route exact path="/signin" component={SignIn} /> */}
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/reset" component={Reset} />
        <Route path="/passwordChange/:hash" component={PasswordReset} />

        <Route path="/m/:username" component={Profile} />

        {/* <Route path="/t" component={Messenger} /> */}

        <Route path="/search/:query" component={Search} />

        <Route path="/blogs" component={Blogs} />
        <Route path="/blog/:blogId" component={Blog} />
        <Route path="/public" component={Public} />
        {/* <Route path="/test" component={Test} /> */}
        {/* <Route path="/cv/:token" component={Cv} /> */}
      </Switch>
    </Router>
  );
};

export default Routes;

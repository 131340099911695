import React, { Suspense, useEffect } from "react";

import { Spinner } from "reactstrap";

import AuthProvider from "./context/AuthProvider";
import { OverlayProvider } from './context/OverlayContext'; // Import OverlayProvider

import ApolloProviderWrapper from "./utils/ApolloProviderWrapper";
import Routes from "./utils/Routes";

import "./App.css";

function App() {
  // const theme = localStorage.getItem("darkTheme");

  // useEffect(() => {
  //   if (theme) {
  //     document.body.classList.add("darktheme");
  //   }
  // }, []);

  return (
    <AuthProvider>
      <ApolloProviderWrapper>
        <OverlayProvider> {/* Wrap your components with OverlayProvider */}
          <Suspense
            fallback={
              <div className="text-center ml-1">
                <Spinner style={{ width: "3rem", height: "3rem" }} type="grow" children=''/>
              </div>
            }
          >
            <Routes />
          </Suspense>
        </OverlayProvider>
      </ApolloProviderWrapper>
    </AuthProvider>
  );
}

export default App;
